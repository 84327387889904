import React, { memo } from "react";
import { BorderedCard } from "../CoreUI";
import { PaddedContainer } from "@disco/disco_core";
import "./central-view.css";
import { motion } from "framer-motion";
import anim from "../../utils/anim";

const CentralView = memo(
	({
		className = "",
		background,
		showMobileLogo,
		mobileHeading,
		...rest
	}) => {
		return (
			<PaddedContainer className={`central-view ${className}`}>
				<motion.img
					src={background}
					alt=""
					className="central-view-background"
					variants={anim.centralBackground}
					initial="initial"
					animate="animate"
					exit="exit"
				/>

				<BorderedCard
					centered
					{...rest}
					className={"central-view-hero"}
				/>
			</PaddedContainer>
		);
	}
);

export default CentralView;
