import {
	Button,
	CheckCircleIcon,
	FONT_COLOR,
	FONT_SIZE,
	Asset,
	Loader,
	MoreHorizontalIcon,
	PaddedContainer,
	RemoteSelect,
	SPACING,
	StatusLabel,
	Table,
	Tag,
	Text,
	TitleRow,
} from "@disco/disco_core";
import React, {
	useCallback,
	forwardRef,
	useEffect,
	memo,
	useRef,
	useState,
} from "react";
import {
	DELETE_INVITE_URL,
	FETCH_SENT_INVITES_URL,
	RESEND_INVITE_URL,
	SEND_INVITE_URL,
} from "../../conf";
import useResource from "../../hooks/useResource";
import parseError from "../../utils/parseError";
import validateEmail from "../../utils/validEmail";
import { FilledButton, Input } from "../CoreUI";

import SettingsModule, { SettingsModuleSection } from "./SettingsModule";
import "./team-module.css";

const STATUS = {
	SUBMITTED: "submitted",
	ACCEPTED: "accepted",
	REJECTED: "rejected",
	CANCELLED: "cancelled",
	ADMIN: "admin",
};

const HEADERS = [
	{
		text: "Name",
		dataField: "name",
	},
	{
		text: "Status",
		dataField: "status",
	},
	{
		text: "",
		dataField: "resend",
	},
	{
		text: "",
		dataField: "options",
	},
];

const TeamInvite = memo(
	({ requested_user, remote_id, status, onDelete, isOriginal }) => {
		const [payload, setPayload] = useState({});
		const [{ loading, data }, update, reset] = useResource(payload, false);

		const [resent, setResent] = useState(false);

		const RESEND = 1,
			DELETE = 2;

		const updateType = useRef();

		const handleUpdate = (type) => {
			if (loading) return;

			updateType.current = type;
			setPayload(
				type === DELETE
					? { url: DELETE_INVITE_URL(remote_id), method: "PUT" }
					: { url: RESEND_INVITE_URL(remote_id), method: "PUT" }
			);
			update();
		};

		useEffect(() => {
			if (!data) return;
			if (updateType.current === DELETE) {
				if (typeof onDelete === "function") onDelete(remote_id);
			} else if (updateType.current === RESEND) {
				setResent(true);
			}
			reset();
		}, [data, reset, onDelete, remote_id]);

		return (
			<tr>
				<td>
					<Text size={FONT_SIZE.BODY}>{requested_user}</Text>
				</td>
				<td>
					<Tag
						type={
							status === STATUS.ACCEPTED ||
							status === STATUS.ADMIN
								? "success"
								: "info"
						}
						icon={
							status === STATUS.ACCEPTED ? (
								<CheckCircleIcon width={20} height={20} />
							) : null
						}
					>
						{status === STATUS.ADMIN
							? "Admin"
							: status === STATUS.ACCEPTED
							? "Accepted"
							: "Invited"}
					</Tag>
				</td>
				<td>
					{status === STATUS.ACCEPTED || status === STATUS.ADMIN ? (
						"-"
					) : loading ? (
						<Loader small />
					) : (
						<Button
							disabled={resent}
							small
							onClick={() => handleUpdate(RESEND)}
						>
							{resent ? "Resent!" : "Resend"}
						</Button>
					)}
				</td>
				<td>
					{isOriginal ? (
						""
					) : (
						<RemoteSelect
							placeholder="Options"
							className="team-module-dropdown"
							options={{
								remove: "Remove member",
							}}
							activator={
								<Asset
									size={20}
									color={FONT_COLOR.DARK}
									marginRight={SPACING.TINY}
								>
									<MoreHorizontalIcon />
								</Asset>
							}
							hideToggleIcon
							value={""}
							multiple={false}
							onChange={({ target: { value } }) => {
								if (value === "remove") {
									handleUpdate(DELETE);
								}
							}}
						/>
					)}
				</td>
			</tr>
		);
	}
);

const TeamModule = memo(
	forwardRef(({ user }, ref) => {
		const [success, setSuccess] = useState(false);
		const [email, setEmail] = useState("");
		const [formError, setFormError] = useState();
		const [invites, setInvites] = useState([]);

		const [{ loading, data: invitesData, error }, , reset] = useResource({
			url: FETCH_SENT_INVITES_URL,
		});

		const [
			{ loading: sendLoading, data: sendData, error: sendError },
			sendInvite,
			sendReset,
		] = useResource(
			{
				url: SEND_INVITE_URL,
				method: "POST",
				data: { email },
			},
			false
		);

		useEffect(() => {
			if (!invitesData) return;
			const requests = [...invitesData.requests];
			if (invitesData.original_email) {
				requests.push({
					requested_user: invitesData.original_email,
					remote_id: "",
					status: STATUS.ADMIN,
				});
			}
			setInvites(requests);
			reset();
		}, [invitesData, reset]);

		useEffect(() => {
			if (!sendData) {
				return;
			}
			setSuccess(true);
			setTimeout(() => {
				setSuccess(false);
				setEmail("");
			}, 2000);

			setInvites((invites) => [sendData.team_request, ...invites]);

			sendReset();
		}, [sendData, sendReset]);

		const invite = useCallback(() => {
			if (!validateEmail(email))
				return setFormError("Please enter a valid email");

			setFormError("");
			sendInvite();
		}, [sendInvite, email]);

		const handleDelete = useCallback((id) => {
			setInvites((invites) =>
				invites.filter((invite) => invite.remote_id !== id)
			);
		}, []);

		const renderInvite = (row, index) => {
			return (
				<TeamInvite
					{...row}
					key={row.remote_id}
					isOriginal={index === invites.length - 1}
					onDelete={handleDelete}
				/>
			);
		};

		return (
			<>
				<SettingsModule ref={ref}>
					<SettingsModuleSection
						tightTop
						title={
							<TitleRow
								title="Team settings"
								tooltip={
									<>
										<Text size={FONT_SIZE.LG_BODY} thick>
											Team settings
										</Text>
										<Text
											size={FONT_SIZE.BODY}
											color={FONT_COLOR.MID}
											vPadding={SPACING.MICRO}
											tightBottom
										>
											Add new members to your team, and
											view sent invitations
										</Text>
									</>
								}
							/>
						}
					>
						<PaddedContainer vPadding={SPACING.REGULAR} tightBottom>
							<Text
								size={FONT_SIZE.BODY}
								color={FONT_COLOR.MID}
								inline
							>
								You can add unlimited team members for{" "}
								<Text thick inline color={FONT_COLOR.DEFAULT}>
									{user.publisher.name}
								</Text>
							</Text>
						</PaddedContainer>

						<section
							className={`team-module-invite ${
								success ? "team-module-invite-success" : ""
							}`}
						>
							<Input
								disabled={success || loading}
								value={email}
								onChange={({ target: { value } }) =>
									setEmail(value)
								}
								placeholder={"Enter the email to invite"}
							/>
							<section className="team-module-invite-action">
								{sendLoading ? (
									<Loader />
								) : (
									<FilledButton
										disabled={success || loading}
										green={success}
										onClick={invite}
									>
										{success ? "Sent!" : "Send an invite"}
									</FilledButton>
								)}
							</section>
						</section>
						{(formError || sendError) && (
							<StatusLabel className="team-module-invite-error">
								{formError || parseError(sendError, true)}
							</StatusLabel>
						)}
					</SettingsModuleSection>
				</SettingsModule>

				<SettingsModule tightTop>
					<PaddedContainer vPadding={SPACING.TINY} tightBottom>
						<Table
							title="Team invitations and members"
							headers={HEADERS}
							data={invites}
							loading={loading}
							sortable={true}
							renderRow={renderInvite}
						/>
						{error && (
							<StatusLabel>{parseError(error)}</StatusLabel>
						)}
					</PaddedContainer>
				</SettingsModule>
			</>
		);
	})
);

export default TeamModule;
