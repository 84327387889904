import React from "react";
import {
	Button,
	ClockIcon,
	FONT_COLOR,
	FONT_SIZE,
	Modal,
	PaddedContainer,
	SPACING,
	Text,
	useModal,
} from "@disco/disco_core";
import { useFormContext } from "react-hook-form";

const LinkEmailModal = ({ onConfirm, ...props }) => {
	return (
		<Modal
			className="link-email-modal"
			{...props}
			title="Link your existing account"
		>
			<Text color={FONT_COLOR.MID} size={FONT_SIZE.LG_BODY}>
				Linking your email connects your existing account to this one,
				allowing you to use the same email across multiple stores. Your
				account data will remain unchanged.
			</Text>

			<PaddedContainer
				marginTop={SPACING.REGULAR}
				className="link-email-modal-buttons"
			>
				<Button secondary onClick={props.onClose}>
					Cancel
				</Button>

				<Button onClick={onConfirm}>Link email</Button>
			</PaddedContainer>
		</Modal>
	);
};

export default function EmailExists() {
	const { clearErrors, setError, setValue, watch } = useFormContext();
	const { handleClose, open, setOpen } = useModal(false);

	const value = watch("existing");

	const handleOnClear = () => {
		setOpen(false);

		setValue("existing", false);

		clearErrors("password");

		setValue("email", "");

		setError("email", {
			message: "Linking canceled. Re-enter your email to retry.",
		});
	};

	if (value === true) {
		return (
			<PaddedContainer
				flexContent
				alignContentCenter
				marginTop={SPACING.TINY}
				style={{ justifyContent: "space-between" }}
			>
				<Text color="--disco-yellow-mid">
					<PaddedContainer flexContent alignContentCenter>
						<ClockIcon size={18} />

						<span style={{ marginLeft: "0.5rem" }}>
							Email link in progress
						</span>
					</PaddedContainer>
				</Text>

				<span className="span-as-anchor" onClick={handleOnClear}>
					Clear
				</span>
			</PaddedContainer>
		);
	}

	const handleOnConfirm = () => {
		setValue("existing", true);

		setValue("password", "");

		setError("password", {
			message:
				"To confirm your account, enter your existing account password.",
		});
	};

	return (
		<>
			An account already exists with this email. If you'd like to create a
			new account with the same email address,{" "}
			<span className="span-as-anchor" onClick={() => setOpen(true)}>
				link your existing email
			</span>
			. If not, use a different email address.
			<LinkEmailModal
				open={open}
				onClose={handleClose}
				onConfirm={handleOnConfirm}
			/>
		</>
	);
}
