import React, { useState } from "react";
import { Page } from "../../components/CoreUI";

import { AnimatePresence } from "framer-motion";
import { Navigate } from "react-router-dom";
import RegisterView from "../../components/centralViews/RegisterView";
import isAuth from "../../utils/isAuth";
import makeRedirectURL from "../../utils/makeRedirectURL";
import "./register-page.css";

const RegisterPage = React.memo(function () {
	const [auth, setAuth] = useState(isAuth()[0] ? 1 : 0);

	if (auth) {
		return <Navigate to={makeRedirectURL("/")} />;
	}

	return (
		<Page className="register-page">
			<AnimatePresence>
				<RegisterView setAuth={setAuth} />
			</AnimatePresence>
		</Page>
	);
});

export default RegisterPage;
