import React, { memo } from "react";
import { Link } from "react-router-dom";

import "./register-view.css";
import { GA_CODE, PLATFORM_IDS, SHOPIFY_APP_STORE_URL } from "../../conf";
import { GET } from "../../utils/GET";
import fromShopify from "../../utils/fromShopify";
import ReactGA from "react-ga";
import windowRedirect from "../../utils/windowRedirect";

import background from "../../img/register-background.png";
import PlatformViews from "./PlatformViews";
import RegistrationForm from "./RegistrationForm";
import getPlatformList from "../../utils/getPlatformList";
import { PaddedContainer, SPACING } from "@disco/disco_core";
import { motion } from "framer-motion";
import anim from "../../utils/anim";
import { BorderedCard } from "../CoreUI";
import colorLogo from "../../img/disco-logo-color.svg";
import { useSearchParams } from "react-router-dom";

// Google Analytics
ReactGA.initialize(GA_CODE, { testMode: process.env.NODE_ENV === "test" });
ReactGA.pageview(window.location.pathname + window.location.search);

const steps = [0, 1];

const platformList = getPlatformList();

const platformIds = platformList.map(({ id }) => id);

const RegisterView = memo(({ setAuth }) => {
	const store_hash = GET("store_hash");

	const [searchParams, setSearchParams] = useSearchParams();

	const setSingleSearchParam = (key, value, options) => {
		const currentValue = Number(searchParams.get(key));

		if (value === currentValue) {
			return;
		}

		setSearchParams((params) => {
			params.set(key, value);

			return params;
		}, options);
	};

	const [savedPlatform, savedStep] = ["platform", "step"].map((key) =>
		Number(searchParams.get(key))
	);

	const step =
		savedStep && steps.includes(savedStep)
			? savedStep
			: !store_hash && !fromShopify()
			? 0
			: 1;

	const platform =
		savedPlatform && platformIds.includes(savedPlatform)
			? savedPlatform
			: undefined;

	const handlePlatformSubmit = (platform) => {
		if (PLATFORM_IDS.shopify === platform) {
			windowRedirect(SHOPIFY_APP_STORE_URL);
		} else {
			setSingleSearchParam("step", 1);
		}
	};

	const setPlatform = (value) =>
		setSingleSearchParam("platform", value, { replace: true });

	return (
		<PaddedContainer className="central-view register-view">
			<motion.img
				src={background}
				alt=""
				className="central-view-background"
				variants={anim.centralBackground}
				initial="initial"
				animate="animate"
				exit="exit"
			/>

			<BorderedCard centered className={"central-view-hero"}>
				<PaddedContainer marginBottom={SPACING.LARGE}>
					<img src={colorLogo} style={{ height: 36 }} alt="" />
				</PaddedContainer>

				{step == 0 ? (
					<PlatformViews
						platform={platform}
						setPlatform={setPlatform}
						handleSubmit={handlePlatformSubmit}
					/>
				) : (
					<RegistrationForm setAuth={setAuth} platformId={platform} />
				)}

				<section className="register-view-login">
					Already have an account? <Link to={"/login"}>Sign in</Link>
				</section>
			</BorderedCard>
		</PaddedContainer>
	);
});

export default RegisterView;
